import React from 'react'
import ReactDOM from 'react-dom'

const Intro =()=>{
        return(
            <div className=''>
                Intro
            </div>
        )
}

export default Intro;
