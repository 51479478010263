import React from 'react'
import ReactDOM from 'react-dom'
import Seo from "../Components/Seo"

const NotFound =()=>{
        return(
            <div className=''>
                        <Seo title="404: Sayfa Bulunamadı" />

                NotFound
            </div>
        )
}

export default NotFound;
